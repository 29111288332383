@import "../../bower_components/bootstrap-less/less/bootstrap.less";
@import "../../bower_components/bootstrap-switch/src/less/bootstrap3/bootstrap-switch.less";
//@import "../../bower_components/font-awesome/less/font-awesome.less";

@grid-columns: 20;

@font-family-sans-serif: "Roboto", "Source Sans Pro", "Helvetica Neue", Helvetica, Arial, sans-serif;
@headings-font-weight: 400;
@line-height-large: 1.33;
@font-size-base: 13px;
@border-radius-large: 5px;
@border-radius-small: 2px;
@zindex-popover: 1010;
@zindex-tooltip: 1030;
@container-tablet: ((720px + @grid-gutter-width));
@container-desktop: ((940px + @grid-gutter-width));
@container-large-desktop: ((1140px + @grid-gutter-width));
@modal-inner-padding: 20px;
@badge-font-weight: normal;
@breadcrumb-separator: ">";

//Will be redefined by theme later
@chart-bar-color: pink;
@chart-bar-color-alt: pink;
@chart-text-color: pink;

html,
body {
  height: 100%;
  background: @body-bg;
}

.padding-bottom-70 {
  padding-bottom: 70px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-bottom-30 {
  padding-bottom: 30px;
}

.padding-bottom-0 {
  padding-bottom: 0;
}

#push,
#footer {
  height: 70px;
  z-index: 100;
}

#update-footer {
  position: fixed;
  left: 0;
  //bottom:35px;
  height: 70px;
  width: 100%;
}

#downloader-status-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 35px;
  width: 100%;
  z-index: 100;
}

/* ------ featurette */
.featurette {
  display: table;
  width: 100%;
  height: 10%; /* set a pixel height and then remove the body,html height */
  vertical-align: middle;
  color: @body-bg;
}

.featurette-inner {
  display: table-cell;
  vertical-align: middle;
}

.featurette .search {
  padding: 3%;
  max-width: 980px; /*max width of search*/
  margin: 0 auto;
}

.no-margin-top {
  margin-top: 0
}

/* ------ form-control-lg */
.form-control-lg {
  min-height: 46px;
  border-radius: 6px;
  font-size: 18px;
  line-height: normal;
}


.td-embedded:first-child {
  padding-left: 15px;
}

a.expand-collapse:focus {
  text-decoration: none;
}

.expand-collapse-hidden {
  visibility: hidden;
}

.hidden-keep-space {
  visibility: hidden;
}

.caret-reversed {
  transform: rotate(180deg);
}

.search-input {
  width: 80%;
}

.episode-input {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  border-radius: 0;
}

.season-input {
  border-radius: 0;
}

.search-border {
  border-bottom-right-radius: @border-radius-base !important;
  border-top-right-radius: @border-radius-base !important;
  //border-right-width: 0px !important;
}

.search-category-button {
  min-width: 105px; //Enough for "Movies HD"
  text-align: left;
}

.by-id-checkbox {

  font-size: @font-size-base;

  color: @input-color;
  background-color: @input-bg;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid @input-border;
  border-right: 0px;

  border-radius: @input-border-radius; // Note: This has no effect on <select>s in some browsers, due to the limited stylability of <select>s in CSS.

  // Reset height for `textarea`s
  textarea& {
    height: auto;
  }
}

.cursor-default {
  cursor: pointer;
}

.no-underline {
  text-decoration: none !important;
}

.nzb-spinning {
  background-image: url('../img/spinner.gif');
}

.addable-nzb {
  display: inline-block;
}

.sabnzbd {
  background-image: url('../img/sab.png');
}

.sabnzbd-success {
  background-image: url('../img/sabsuccess.png');
}

.sabnzbd-error {
  background-image: url('../img/saberror.png');
}

.nzbget {
  background-image: url('../img/nzbget.png');
}

.nzbget-success {
  background-image: url('../img/nzbgetsuccess.png');
}

.nzbget-error {
  background-image: url('../img/nzbgeterror.png');
}

.icon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  vertical-align: middle;
}

.config-field {
  width: 400px;
}

.checkbox-field {
  width: auto;
}

.config-select {
  margin: 30px;
  text-align: left;

}

.config-field-container {
  margin: 30px;
  text-align: left;
}

.control-label {

}

.config-select-field > button {
  background-color: @gray-darker;
}

.growl-container > .icon {
  width: inherit;
  height: inherit;
}

.navbar .nav,
.navbar .nav > li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  *zoom: 1; /* hasLayout ie7 trigger */
  vertical-align: top;
}

.navbar-inner {
  text-align: center;
}

a.disabled {
  color: @gray-dark;
  text-decoration: none;
  cursor: default;
}

a.disabled:hover {
  color: @gray-dark;
  text-decoration: none;
  cursor: default;
}

.visibility-hidden {
  visibility: hidden;
}

.search-results-table {
  display: table;
  width: 100%;
  background-color: @table-bg;
  border-collapse: separate;
}

.search-results-header {
  //display: table-header-group;
  text-align: center;
}

.search-results-body {
  //display: table-row-group;
}

.search-results-row {
  //display: table-row;

}

.other-duplicate-toggle {
  margin-left: 18px;
}

.toggle-placeholder {
  margin-left: 36px
}

.search-results-cell {
  //display: table-cell;
  text-align: left;
  border-top-width: 1px;
}

.nav-tabs > li, .nav-pills > li {
  float: none;
  display: inline-block;
  *display: inline; /* ie7 fix */
  zoom: 1; /* hasLayout ie7 trigger */
}

.nav-tabs, .nav-pills {
  text-align: center;
}

.table > tbody > tr > td {
  text-align: left;
}

.table > thead > tr > th {
  text-align: left;
}

.table > tbody + tbody {
  border-top: 1px solid @table-border-color;
}

.tab-content {
  padding-top: 30px;
}

.glyphicon-refresh-animate {
  -webkit-animation: spinw .7s infinite linear;
  -moz-animation: spinm .7s infinite linear;
}

@-webkit-keyframes spinw {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes spinm {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}

/*
 * Pulse animation
*/

@-webkit-keyframes pulsew {
  0% {
    -webkit-transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pulsem {
  0% {
    -moz-transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.1);
  }
  100% {
    -moz-transform: scale(1);
  }
}

.pulse {
  -webkit-animation-name: pulsew;
  -moz-animation: pulsem;
}

.config-button {
  border: 1px solid #1c1e22;
  border-bottom-color: transparent;
  border-radius: @border-radius-base @border-radius-base 0 0;
  margin-top: 7px

}

.config-help-button {
  margin-right: 10px;
}

.pulse2 {
  animation: pulse2 1s linear infinite;
  animation-direction: alternate-reverse;
}

@keyframes pulse2 {
  0% {

    transform: scale(1);
  }
  50% {

    transform: scale(1.1);
  }
  100% {

    transform: scale(1.2);
  }

}

.fuzzy-nfo {
  color: lighten(@gray, 30%);
}

.no-nfo {
  visibility: hidden;
}

.pagination {
  margin: 0;
}

.ui-select-bootstrap .ui-select-choices-row > a {
  background-color: @input-bg;
  color: @input-color;
}

.ui-select-match > .btn {
  background-color: @input-bg;
  color: @input-color;
}

.dropdown-menu {
  background-color: @input-bg;

  #freetext-filter-input {
    color: black;
  }
}

.bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-primary {
  background-color: @brand-success;
  color: @switch-color;
}

.bootstrap-switch-handle-off {
  //padding: 6px !important;
}

.input-group-btn1 {
  border: 1px solid @input-border;
  border-left-width: 0;
  border-radius: @border-radius-base;
}

.input-group-btn2 {
  border: 1px solid @input-border;
  border-radius: @border-radius-base;
}

.input-group-btn:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.input-group-btn:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  border-left-width: 0;
}

.search-results-table {
  border: 1px solid @brand-primary;
}

.search-results-table > tbody:nth-child(3) > tr > td {
  border-top-width: 0 !important;
}

.odd {
  background-color: darken(@table-bg, 5%);
}

.title-group-expanded {
  background-color: darken(@table-bg, 5%);
}

.search-results-cell:focus {
  outline: none;
}

@font-size-result-title: 13px;
@font-size-result-non-title: 12px;

.duplicate {
  background-color: darken(@table-bg, 15%);
}

.result-title {
  font-size: @font-size-result-title;
  width: 47.5%;
}

.result-indexer {
  font-size: @font-size-result-non-title;
  width: 8%;
}

.result-category {
  font-size: @font-size-result-non-title;
  width: 6%;
}

.result-size {
  font-size: @font-size-result-non-title;
  width: 6%;
}

.result-details {
  font-size: @font-size-result-non-title;
  width: 6%;
}

.result-age {
  font-size: @font-size-result-non-title;
  width: 4.5%;
}

.result-links {
  font-size: @font-size-result-non-title;
  width: 8%;
  padding-right: 4px !important;
}

.form-control {
  height: 36px;
}

.form-control::-webkit-input-placeholder {
  color: @input-color-placeholder;
}

.form-control:-moz-placeholder {
  color: @input-color-placeholder;
}

.form-control::-moz-placeholder {
  color: @input-color-placeholder;
}

.form-control:-ms-input-placeholder {
  color: @input-color-placeholder;
}

.bootstrap-switch {
  border: 1px solid @input-border;
}

.indexer-checkbox {
  margin-left: 10px !important;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: @block-overlay-bg;
}

.block-ui-message {
  background-color: @block-message-bg;
  color: @block-message-color;
}

.nfo {
  background-color: transparent;
  border-width: 0;
  text-align: left;
  color: @text-color;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

[ng\:cloak], [ng-cloak], .ng-cloak {
  display: none !important;
}

pre {
  min-height: 50px;
}

.system-tab-content {
  //width: 900px;
  margin: auto;
}

.panel {
  border-color: @navbar-default-border;
}

.panel-default > .panel-heading {
  background-color: @navbar-default-bg;

  h3 {
    color: @navbar-inverse-bg;
  }
}

.help-block {
  text-align: left;

  a {
    text-decoration: underline;
  }
}

input:focus + .input-group-addon {
  @color-rgba: rgba(red(@input-border-focus), green(@input-border-focus), blue(@input-border-focus), .6);
  border-color: @input-border-focus;
  outline: 0;
  .box-shadow(~"inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px @{color-rgba}");
}

.input-group-addon {
  cursor: default;
}

#banner {
  background: @banner-url no-repeat center;
  background-size: contain;
  height: 150px;
  margin: 0 auto 25px auto;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}

.input-group-addon {
  color: @input-group-addon-color;
}

.history-title {
  font-style: italic;
  color: @input-color-placeholder;
}

.has-error .control-label {
  color: @brand-danger;
}

.indexer-button {
  margin-right: 10px;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;

  &.config-incomplete {
    background-color: @brand-danger;
    border-color: @brand-danger;
  }

  &.not-all-checked {
    background-color: @brand-warning;
    border-color: @brand-warning;
  }

}

.spinner {
  display: inline-block;
  opacity: 0;
  max-width: 0;
  -webkit-transition: opacity 0.25s, max-width 0.45s;
  -moz-transition: opacity 0.25s, max-width 0.45s;
  -o-transition: opacity 0.25s, max-width 0.45s;
  transition: opacity 0.25s, max-width 0.45s;
  /* Duration fixed since we animate additional hidden width */
}

.has-spinner.active {
  cursor: progress;
}

.has-spinner.active .spinner {
  opacity: 1;
  max-width: 50px;
  /* More than it will ever come, notice that this affects on animation duration */
}

.indexer-score-input {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
  border-radius: 0;
}

.indexer-score-input-enabled {
  border-color: @brand-success;
}

.indexer-input-input-enabled:focus {
  border-color: @brand-success;
}

.indexer-score-input-disabled {
  border-color: @btn-default-border;
}

.indexer-score-input-disabled:focus {
  border-color: @btn-default-border;
}

.indexer-score-addon-disabled {
}

.btn:focus {
  outline: none;
}

.btn-default:active {
  background-color: @btn-default-bg;
}

.filter-button.btn-default:focus
  //.filter-button.active
{
  background-color: @btn-default-bg !important;
  border-color: @btn-default-bg !important;
  color: @btn-default-color !important;

  &.active {
    background-color: @btn-default-active-bg !important;
    color: @btn-default-color !important;
  }
}

.inline-filter { //Filter box
  display: inline;
  width: 300px;
  padding-left: 5px;
  font-size: @font-size-base;
  height: 24px;
  margin-left: 5px;
}

.indexer-input:focus {
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.indexer-input-focused {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.table .table {
  background-color: @table-bg;
}

.results-table-expanded {
  border-color: red;
}

.indexer-statuses-accordion {
  color: @text-color;
}

.tooltip-inner {
  max-width: 350px;
}

.caption {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-bottom: 5px;
  color: @brand-primary;
  text-align: left;
}

//Charts

.nv-axis text {
  fill: @chart-text-color;
}

.nv-bar text {
  fill: @chart-text-color !important;
}

.nvd3 .nv-discretebar .nv-groups rect {
  fill: @chart-bar-color !important;
}

.nvd3 .nv-multibarHorizontal .nv-groups rect {
  fill: @chart-bar-color !important;
}

.nv-groups .nv-series-0 rect {
  fill: @chart-bar-color !important;
}

//Legend
g.nv-multiBarWithLegend > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(1) > circle {
  //.nv-series:nth-child(1) > circle {
  fill: @chart-bar-color !important;
  stroke: @chart-bar-color !important;
}

.nv-groups .nv-series-1 rect {
  fill: @chart-bar-color-alt !important;
}

//Legend
g.nv-multiBarWithLegend > g > g.nv-legendWrap.nvd3-svg > g > g > g:nth-child(2) > circle {
  fill: @chart-bar-color-alt !important;
  stroke: @chart-bar-color-alt !important;
}

.nv-legend-text {
  fill: @chart-text-color;
}

g.nv-multiBarHorizontalChart > g > g > g > g > g > text {
  transform: rotate(-31deg);
}

.nvtooltip {
  background-color: @modal-content-bg;
  color: @text-color;
}

.tick line {
  opacity: 0 !important;
}

.generate-multibar-colors(@n, @i: 1) when (@i =< @n) {
  .nvd3 .nv-multibarHorizontal > g > g.nv-groups > g > g:nth-child(@{i}) > rect {
    fill: lighten(@chart-bar-color, (@i * 2%)) !important;
  }

  .nvd3 .nv-multibar > g > g.nv-groups > g:nth-child(1) > rect:nth-child(@{i}) {
    fill: lighten(@chart-bar-color, (@i * 2%)) !important;
  }

  .nvd3 .nv-multibar > g > g.nv-groups > g:nth-child(1) > rect:nth-child(@{i}) {
    fill: lighten(@chart-bar-color-alt, (@i * 2%)) !important;
  }

  .generate-multibar-colors(@n, (@i + 1));
}

.generate-week-colors(@n, @i: 1) when (@i =< @n) {
  .weeklyChart .nvd3 .nv-discretebar > g > g.nv-groups > g > g:nth-child(@{i}) > rect {
    fill: lighten(@chart-bar-color, (@i * 7%)) !important;
  }

  .generate-week-colors(@n, (@i + 1));
}

.generate-day-colors(@n, @i: 1) when (@i =< @n) {
  .dailyChart .nvd3 .nv-discretebar > g > g.nv-groups > g > g:nth-child(@{i}) > rect {
    fill: lighten(@chart-bar-color, (@i * 2%)) !important;
  }

  .generate-day-colors(@n, (@i + 1));
}

.column-filter {
  border: none !important;
  background: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  padding: 0;
  margin-left: 3px;
  font-size: 80% !important;
}

.column-filter-boolean {
  margin-bottom: 0;

  .radio {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.narrow-row {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.filter-active {
  color: @brand-success;
}

.help-block {
  margin-top: 0;
  margin-bottom: 0;

}

.rejected-tooltip {
  width: unset;
}

.rejected-tooltip-table {
  margin-top: 10px;
  background-color: transparent;
}

.warning {
  color: @brand-warning;
}

.error, .danger {
  color: @brand-danger;
}

.error-message {
  color: @brand-danger-message;
}

@media (min-width: 1000px) {
  .modal-xl {
    width: 950px;
  }
}

@media (min-width: 1500px) {
  .modal-xl {
    width: 1400px;
  }
}

.chip-tmpl {
  display: inline-block;
  margin: 0 5px 5px 0;
}

.default-chip {
  border: 1px solid @input-border;
  border-radius: 5px;
  background: @input-bg;
  color: @input-color;
  padding: 0 2px 0 2px;
}

.chip-tmpl:focus {
  outline: none;
}

chip-tmpl:focus .default-chip {
  border: 2px solid #9e9e9e;
  background: @input-bg;
  color: @input-color;
}

.chip-failed .default-chip {
  color: @brand-danger;
}

.chips {
  display: block;
  padding: 2px 5px 1px 12px;
  background: @input-bg;
  height: unset;
  min-height: 34px;
  text-align: left;
}

.chip-control {
  background: @input-bg;
  color: @input-color;
  margin-top: 5px;
}

.chips > div {
  display: inline;
}

.chips > div > input {
  height: 100%;
  border: none;
  font-size: @font-size-base;
}

.chips > div > input:focus {
  outline: none;
}

.chip-out-focus {
  border: 1px solid @input-border;
}

.chip-in-focus {
  border: 1px solid @input-border-focus;
}

.remove-chip {
  font-size: 10px;
}

select.form-control {
  padding-left: 8px; //Content has some paddding, so reduce padding to make actual/visual padding the same as the others
}

.input-lg {
  font-size: 16px;
}

.stat-box {
  border-radius: @border-radius-small;
  border: 1px solid @table-border-color;

  .caption {
    margin-top: 10px;
    padding-left: 15px;

    .bootstrap-switch {
      margin-right: 20px;
    }
  }
}

.formly-field-duoSetting > div {
  margin-bottom: 0;
}

.formly-multiselect {
  float: left;
}


.popover {
  max-width: 450px;

  .popover-content {
    min-width: 400px;
  }
}


.config-label {
  width: 20%;
}

.config-content {
  width: 30%;
  padding-right: 0;
}

.config-tooltip {
  padding-left: 10px;
  padding-right: 10px;
  width: 3%;
  display: block;
}


.config-help {
  padding-left: 0;
  width: 47%;
  padding-right: 20px;
}

.setting-wrapper {


  .config-content {
    padding-right: 0;
  }


  .config-help {
    padding-left: 0;
    width: 47%;
    padding-right: 20px;
  }

  .align-middle {
    vertical-align: middle;
    height: 36px;
    display: table-cell;
  }
}



.config-box {

  .config-tooltip {
    padding-left: 15px;
    padding-right: 15px;
  }

  .config-help {
    padding-left: 15px;
    padding-right: 20px;
    width: 45%;
  }

}


.config-field-tooltip {
  max-width: 400px;
}

.config {
  .config-left-space {
    width: 10%;
  }
}

.config-fieldset-legend {
  float: left;
  margin-left: 292px;
}


.formly > .formly-field-group {
  position: relative;
  background-color: fade(@modal-content-bg, 50%);
  border: 1px solid @modal-content-border-color;
  border-radius: @border-radius-large;
  //.box-shadow(0 1px 5px rgba(0, 0, 0, .5));
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: 0;


}

.formly-field-repeatSection {

  .repeatsection {
    box-shadow: none;
    border: none;
    margin-top: 25px;
  }

}

.repeatWrapperClass {
  background-color: fade(@modal-content-bg, 25%);
  border: 1px solid @modal-content-border-color;
  margin-top: 25px;
  padding-bottom: 45px;

  legend {
    display: inline-block;
    margin-bottom: 0;
    overflow: hidden;
  }


  .repeatsection {
    margin-left: 20px;
    margin-right: 20px;
    background-color: fade(darken(@modal-content-bg, 7%), 25%);
    border: 1px solid @modal-content-border-color;
    border-radius: @border-radius-large;

    &:not(:first-child) {
      margin-top: 20px;
    }

    fieldset {
      margin-left: -15px
    }


    legend {
      //(@font-size-base * 1.5) for regular legends in forms.less
      font-size: (@font-size-base * 1.3);
      padding: 3px;
      margin-left: -7px;
      border: none;
      margin-bottom: 10px;
      overflow: hidden;
    }
  }

  .remove-button, .add-button {
    float: left;
    margin-left: 286px;
    margin-bottom: 15px;
  }

  .remove-button {
    margin-top: 10px;
  }

}


.formly-field-group:not(:first-child) {
  margin-top: 25px;
}

.formly-field-group:first-child {
  margin-top: -25px;
}


a.glyph-link, a.glyph-link:hover {
  text-decoration: none;
}

.dropdown-multiselect {

  &.form-control {
    background-color: transparent;
    border: none;
    padding: 0;
  }

  .dropdown-toggle {
    width: 100%;
    overflow: hidden;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  a:focus {
    outline: none;
  }
}

.indexer-statuses-table {
  .label {
    font-size: 100%;
    font-weight: 100;
  }

  .indexer-status-reason {
    word-break: break-all;
  }
}

.scrollable-menu {
  height: auto;
  max-height: 600px;
  overflow-x: hidden;
}

.log > tr > td.log-line {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: small;
}

.nvtooltip {
  display: none !important;
}

.downloader-status {

  .nvtooltip, .nv-point {
    display: none !important;
  }

  .nv-noData {
    display: none !important;
  }

  path.domain {
    display: none;
  }

  padding-right: 0;

  .nvd3 text {
    display: none;
  }

  .nvd3 {
    .nv-bars {
      rect {
        width: 2px !important;
      }
    }
  }

  chart {
    float: right;
  }

}

.pagination > .active > a {
  //z-index: -1 !important;
}


.cover-modal-dialog {
  .modal-dialog {
    width: min-content;
  }
}